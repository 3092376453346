import GATSBY_COMPILED_MDX from "/Users/grace/Documents/GitHub/TreelandTrailsWebsite/src/content/2022-10-01-legacy-oak/index.mdx";
import React from 'react';
import {graphql, Link} from 'gatsby';
import Layout from '../components/layout/Layout';
import MDXGrid from '../components/layout/MDXGrid';
import Breadcrumb from '../components/layout/Breadcrumb';
import {ParallaxBanner} from 'react-scroll-parallax';
import {MDXProvider} from '@mdx-js/react';
const shortcodes = {
  MDXGrid,
  Link
};
function BlogPost({data, children}) {
  const post = data.mdx;
  const breadcrumbList = [{
    name: 'Thoughts',
    url: '/thoughts'
  }, {
    name: 'Blog',
    url: '/thoughts/blog'
  }, {
    name: post.frontmatter.title || ''
  }];
  let banner = '';
  if (post.frontmatter.heroImg?.publicURL) {
    banner = React.createElement(ParallaxBanner, {
      layers: [{
        image: post.frontmatter.heroImg?.publicURL,
        speed: -20
      }],
      style: {
        aspectRatio: '3.8'
      }
    });
  }
  let title = '';
  if (post.frontmatter.title) {
    title = React.createElement("h1", {
      className: "title"
    }, post.frontmatter.title);
  }
  let subtitle = '';
  if (post.frontmatter.subtitle) {
    subtitle = React.createElement("h2", {
      className: "subtitle"
    }, post.frontmatter.subtitle);
  } else if (post.frontmatter.date) {
    subtitle = React.createElement("h2", {
      className: "subtitle"
    }, post.frontmatter.date);
  }
  return React.createElement(Layout, null, React.createElement(Breadcrumb, {
    crumbList: breadcrumbList,
    showCloseButton: true
  }), React.createElement("div", {
    className: "banner"
  }, banner), React.createElement("div", {
    id: "blog"
  }, React.createElement("div", {
    id: "content"
  }, title, subtitle, React.createElement("div", {
    id: "blog-content"
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children)))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(BlogPost, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
  query BlogQuery($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        subtitle
        date(formatString: "MMMM DD, YYYY")
        introduction
        heroImg {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(width: 600)
          }
        }
      }
      internal {
        contentFilePath
      }
    }
  }
`;
